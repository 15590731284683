import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import WithDefaultLayout from 'components/generic/layouts/default'
import { useRouter } from 'next/router'
import QueryResultHandler from 'components/generic/query-result-handler'
import ENTITY_TYPE from 'enums/entity-type'
import Throw404 from 'components/generic/throw-404'
import Throw500 from 'components/generic/throw-500'
import isValidId from 'lib/validId'
import GET_PROMOTER_HAS_COVER from './GetPromoterHasCoverQuery'

const PromoterLayout = (WrappedComponent) => {
  const Component = (props) => {
    const {
      query: { idOrCountry },
    } = useRouter()

    const validId = isValidId(idOrCountry)

    const queryResult = useQuery(GET_PROMOTER_HAS_COVER, {
      variables: {
        id: idOrCountry,
      },
      skip: !validId,
    })

    if (!validId) return <Throw404 entityType={ENTITY_TYPE.Promoter} />

    return (
      <QueryResultHandler
        {...queryResult}
        dataKey="promoter"
        markup={PromoterLayoutMarkup}
        handleEmpty={() => <Throw404 entityType={ENTITY_TYPE.Promoter} />}
        handleError={() => <Throw500 />}
        WrappedComponent={WrappedComponent}
        id={idOrCountry}
        {...props}
      />
    )
  }

  return Component
}

const PromoterLayoutMarkup = ({ WrappedComponent, data }) =>
  WithDefaultLayout(WrappedComponent, { hideLeader: data.hasCover })({
    id: data.id,
  })

PromoterLayoutMarkup.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hasCover: PropTypes.bool.isRequired,
  }).isRequired,
}

export { PromoterLayoutMarkup }
export default PromoterLayout
