import getYear from 'date-fns/getYear'
import useSeo from 'hooks/useSeo'
import seo from 'messages/seo'
import { useIntl } from 'react-intl'
import { useServerTime } from 'context/ServerTimeContext'

const usePromoterSEO = ({
  data,
  overrides,
  noIndex = false,
  pageType,
}: {
  data: {
    promoter: object
    name: string
    blurb: string
    logoUrl?: string
  }
  overrides: {
    title?: string
    description?: string
  }
  noIndex: boolean
  pageType: PROMOTER_PAGE_TYPE
}) => {
  const images = []

  if (data.logoUrl) {
    images.push({ url: data.logoUrl })
  }

  const { getTitle, getDescription, getSharingTitle, getSharingDescription } =
    useSeoMessages()

  const serverTime = useServerTime()

  return useSeo({
    overrides: {
      title: getTitle(data.name, pageType) || overrides?.title,
      description: getDescription(data, pageType) || overrides?.description,
      ogTitle: overrides?.title || getSharingTitle(data.name, pageType),
      ogDescription:
        overrides?.description ||
        getSharingDescription(data, pageType, serverTime),
      images,
      noindex: noIndex,
    },
  })
}

enum PROMOTER_PAGE_TYPE {
  overview = 'overview',
  upcomingEvents = 'upcomingEvents',
  pastEvents = 'pastEvents',
  news = 'news',
}

const useSeoMessages = () => {
  const intl = useIntl()

  const getTitle = (name: string, pageType: PROMOTER_PAGE_TYPE) => {
    switch (pageType) {
      case PROMOTER_PAGE_TYPE.overview:
        return intl.formatMessage(seo.promoterTitleOverview, { name })
      case PROMOTER_PAGE_TYPE.upcomingEvents:
        return intl.formatMessage(seo.promoterTitleUpcomingEvents, { name })
      case PROMOTER_PAGE_TYPE.pastEvents:
        return intl.formatMessage(seo.promoterTitlePastEvents, { name })
      case PROMOTER_PAGE_TYPE.news:
        return intl.formatMessage(seo.promoterTitleNews, { name })
      default:
        return ''
    }
  }

  const getSharingTitle = (name: string, pageType: PROMOTER_PAGE_TYPE) => {
    let tabTitle: string

    switch (pageType) {
      case PROMOTER_PAGE_TYPE.overview:
        tabTitle = intl.formatMessage(seo.promoterTabTitleOverview)
        break
      case PROMOTER_PAGE_TYPE.upcomingEvents:
        tabTitle = intl.formatMessage(seo.promoterTabTitleUpcomingEvents)
        break
      case PROMOTER_PAGE_TYPE.pastEvents:
        tabTitle = intl.formatMessage(seo.promoterTabTitlePastEvents)
        break
      case PROMOTER_PAGE_TYPE.news:
        tabTitle = intl.formatMessage(seo.promoterTabTitleNews)
        break
      default:
        tabTitle = ''
    }

    return tabTitle
      ? intl.formatMessage(seo.promoterSharingTitleDefault, { name, tabTitle })
      : intl.formatMessage(seo.promoterSharingTitleAlt, { name })
  }

  const getDescription = (
    data: {
      name: string
      blurb: string
    },
    pageType: PROMOTER_PAGE_TYPE
  ) => {
    const blurb = getBlurbSafe(data)

    switch (pageType) {
      case PROMOTER_PAGE_TYPE.overview:
        return intl.formatMessage(seo.promoterDescriptionOverview, {
          name: data.name,
          blurb,
        })
      case PROMOTER_PAGE_TYPE.upcomingEvents:
        return intl.formatMessage(seo.promoterDescriptionUpcomingEvents, {
          name: data.name,
          blurb,
        })
      case PROMOTER_PAGE_TYPE.pastEvents:
        return intl.formatMessage(seo.promoterDescriptionPastEvents, {
          name: data.name,
          blurb,
        })
      case PROMOTER_PAGE_TYPE.news:
        return intl.formatMessage(seo.promoterDescriptionNews, {
          name: data.name,
          blurb,
        })
      default:
        return ''
    }
  }

  const getSharingDescription = (
    data: {
      name: string
      blurb: string
    },
    pageType: string,
    serverTime: Date
  ) => {
    const currentYear = getYear(serverTime)
    const followingYear = currentYear + 1

    switch (pageType) {
      case PROMOTER_PAGE_TYPE.overview:
        return getBlurbSafe(data)
      case PROMOTER_PAGE_TYPE.upcomingEvents:
        return intl.formatMessage(
          seo.promoterSharingDescriptionUpcomingEvents,
          {
            name: data.name,
            currentYear,
            followingYear,
          }
        )
      case PROMOTER_PAGE_TYPE.pastEvents:
        return intl.formatMessage(seo.promoterSharingDescriptionPastEvents, {
          name: data.name,
        })
      case PROMOTER_PAGE_TYPE.news:
        return 'RA: Resident Advisor'
      default:
        return ''
    }
  }

  const getBlurbSafe = (data: { blurb: string }) => data.blurb || ''

  return {
    getTitle,
    getSharingTitle,
    getDescription,
    getSharingDescription,
  }
}

export { usePromoterSEO, PROMOTER_PAGE_TYPE }
