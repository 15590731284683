import { gql } from '@apollo/client'

const GET_PROMOTER_DETAILS = gql`
  query GET_PROMOTER_DETAILS($id: ID!) {
    promoter(id: $id) {
      id
      name
      contentUrl
      logoUrl
      hasCover
      coverUrl
      isFestival
      venueId
      area {
        id
        name
        urlName
        country {
          id
          name
          urlCode
        }
      }
    }
  }
`

export default GET_PROMOTER_DETAILS
