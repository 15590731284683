import { gql } from '@apollo/client'

const GET_PROMOTER_HAS_COVER = gql`
  query GET_PROMOTER_HAS_COVER($id: ID!) {
    promoter(id: $id) {
      id
      hasCover
    }
  }
`

export default GET_PROMOTER_HAS_COVER
