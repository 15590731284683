import {
  SubSection,
  Grid,
  Alignment,
  Column,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import messages from 'messages/dict'
import ResponsiveImage from 'components/generic/responsive-image'
import CmsContent from 'components/generic/cms-content'
import { light } from 'themes'

const PromoterDescription = ({ about, promoter, imageUrl }) => {
  const intl = useIntl()

  return (
    <ThemeProvider theme={light}>
      <SubSection.Stacked title={intl.formatMessage(messages.about)}>
        <Grid lCols={12} mCols={4} sCols={1} sWrap>
          {about && (
            <Column lSpan={7} mSpan={2} sSpan={1}>
              <CmsContent content={about} />
            </Column>
          )}
          {imageUrl && (
            <Column lSpan={5} mSpan={2} sSpan={1}>
              <Alignment justifyContent="center">
                <ResponsiveImage
                  url={imageUrl}
                  alt={`${promoter} logo`}
                  sizes={{ m: '50vw', l: '42vw' }}
                />
              </Alignment>
            </Column>
          )}
        </Grid>
      </SubSection.Stacked>
    </ThemeProvider>
  )
}

PromoterDescription.propTypes = {
  about: PropTypes.string,
  promoter: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default PromoterDescription
