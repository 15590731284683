import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import Link from 'components/generic/link'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useUserContext } from 'context/UserContext'

const PromoterLinks = ({ links, id, name }) => {
  const validLinks = links.filter((link) => link.href != null)
  const { user } = useUserContext()

  const onClick = (link) => {
    Tracking.trackMixpanel(TRACKING_EVENT.clickSocialMediaLink, {
      Type: link.type,
      Source: 'Promoter page',
      'Promoter ID': id,
      'Promoter Name': name,
      'User ID': user?.id,
    })
  }

  return (
    <Alignment flexDirection="row" flexWrap="wrap">
      {validLinks.map((link, index) => (
        <Fragment key={index}>
          <Link
            variant={variants.text.small}
            underline
            href={link.href}
            onClick={() => onClick(link)}
          >
            {link.type}
          </Link>
          {index < validLinks.length - 1 && (
            <Text variant={variants.text.small} color="primary">
              ,&nbsp;
            </Text>
          )}
        </Fragment>
      ))}
    </Alignment>
  )
}

PromoterLinks.propTypes = {
  links: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
}

export default PromoterLinks
