import { gql } from '@apollo/client'

const GET_PROMOTER = gql`
  query GET_PROMOTER_DETAIL($id: ID!) {
    promoter(id: $id) {
      id
      name
      contentUrl
      followerCount
      isFollowing
      website
      email
      blurb
      logoUrl
      socialMediaLinks {
        id
        link
        platform
      }
      area {
        id
        name
        urlName
        country {
          id
          name
          urlCode
        }
      }
      tracking(types: [PAGEVIEW]) {
        id
        code
        event
      }
    }
  }
`

export default GET_PROMOTER
