import {
  Grid,
  Column,
  Box,
  SubSection,
  Text,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import VerticalCardGroup from 'components/generic/vertical-card-group'
import Ad from 'components/generic/ad'
import messages from 'messages/events'
import PropTypes from 'prop-types'
import EventListingCard from 'components/events/cards/event-listing'
import arrayHasData from 'lib/arrayHasData'
import ViewMoreButton from 'components/generic/view-more-button'
import { promoterEventsByIdRoute } from 'lib/routes'
import { useRouter } from 'next/router'

const PromoterUpcomingEvents = ({ events }) => {
  const intl = useIntl()

  return (
    <SubSection
      title={intl.formatMessage(messages.upcomingEvents)}
      variant={variants.section.secondary}
    >
      <Grid lCols={12} sCols={1} sWrap>
        {arrayHasData(events) ? (
          <>
            <Column lSpan={7}>
              <UpcomingEvents events={events} />
            </Column>
            <Column lSpan={5}>
              <Ad
                variant={Ad.variants.MPU_General_Large}
                pb={5}
                justifyContent="flex-end"
              />
            </Column>
          </>
        ) : (
          <Column lSpan={7}>
            <Box pb={5}>
              <Text>
                {intl.formatMessage(messages.noUpcomingEventListings)}
              </Text>
            </Box>
          </Column>
        )}
      </Grid>
    </SubSection>
  )
}

const UpcomingEvents = ({ events }) => {
  const intl = useIntl()
  const { asPath } = useRouter()

  return (
    <VerticalCardGroup
      pt={0}
      Button={() => (
        <ViewMoreButton {...promoterEventsByIdRoute(`${asPath}/events`)}>
          {intl.formatMessage(messages.viewMoreEvents)}
        </ViewMoreButton>
      )}
    >
      {events.map((event) => (
        <EventListingCard key={event.id} event={event} />
      ))}
    </VerticalCardGroup>
  )
}

UpcomingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string.isRequired,
      contentUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      interestedCount: PropTypes.number.isRequired,
      pick: PropTypes.object,
      artists: PropTypes.array,
      queueItEnabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
}

PromoterUpcomingEvents.propTypes = {
  events: PropTypes.array.isRequired,
}

export { UpcomingEvents }
export default PromoterUpcomingEvents
