import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import QueryResultHandler from 'components/generic/query-result-handler'
import Redirect from 'components/generic/redirect'
import { BreadcrumbFlagIcon } from 'components/generic/breadcrumb/Breadcrumb'
import ProfileHeader from 'components/shared/profile-header'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'
import get from 'lodash/get'
import Throw404 from 'components/generic/throw-404'
import ENTITY_TYPE from 'enums/entity-type'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import { guideByAreaRoute } from 'lib/routes'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'

import GET_PROMOTER_DETAILS from './GetPromoterDetailQuery'

const PromoterDetailPageTemplate = ({ id, children }) => {
  const queryResult = useQuery(GET_PROMOTER_DETAILS, {
    variables: {
      id,
    },
  })

  return (
    <QueryResultHandler
      {...queryResult}
      dataKey="promoter"
      markup={PromoterDetailPageTemplateMarkup}
      handleEmpty={() => <Throw404 entityType={ENTITY_TYPE.Promoter} />}
    >
      {children}
    </QueryResultHandler>
  )
}

PromoterDetailPageTemplate.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
}

const PromoterDetailPageTemplateMarkup = ({ data, children }) => {
  const { id, name, area, venueId, contentUrl, coverUrl, hasCover, logoUrl } =
    data

  const guideLocationText = useLocalisedAreaName({
    area,
    country: area.country,
  })

  const breadcrumb = getBreadcrumb(area, guideLocationText)

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.viewPromoterPage,
      properties: {
        'Promoter ID': id,
        'Promoter Name': name,
      },
    },
  })

  if (parseInt(venueId) && contentUrl) {
    return <Redirect to={contentUrl} />
  }

  return (
    <>
      <ProfileHeader
        text={name}
        backgroundImage={coverUrl}
        smallImage={hasCover ? null : logoUrl}
        smallImageType={PROFILE_IMAGE_TYPES.logo}
        breadcrumb={breadcrumb}
        noBlur={hasCover}
      />
      {children}
    </>
  )
}

PromoterDetailPageTemplateMarkup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hasCover: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    coverUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    venueId: PropTypes.string,
    contentUrl: PropTypes.string,
    blurb: PropTypes.string,
    area: PropTypes.shape({
      name: PropTypes.string,
      urlName: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
        urlCode: PropTypes.string,
      }),
    }),
  }),
  children: PropTypes.node,
}

const getBreadcrumb = (area, guideLocationText) => {
  const { href: guideHref, as: guideAs } = guideByAreaRoute(
    generateUrlForAreaAndCountry('/guide', area)
  )

  return [
    {
      href: guideHref,
      as: guideAs,
      text: guideLocationText,
      icon: <BreadcrumbFlagIcon urlCode={get(area, 'country.urlCode')} />,
    },
  ]
}

export { PromoterDetailPageTemplateMarkup }
export default PromoterDetailPageTemplate
