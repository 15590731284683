import { gql } from '@apollo/client'

const GET_PROMOTER_NEWS = gql`
  query GET_PROMOTER_NEWS_NEWS($id: ID!) {
    promoter(id: $id) {
      id
      contentUrl
      news(limit: 3) {
        id
        blurb
        date
        imageUrl
        title
        contentUrl
      }
    }
  }
`

export default GET_PROMOTER_NEWS
